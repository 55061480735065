$blue: #007bff
$selected: #007bff

$title: #333
$subtitle: #595959

$icon: #395273
$main: #262262

    
$line: #A6A6A6
$line2: #ececec
$text: #000
$hover: #eaeaea
$disable: #d2d2d2

$button-ok: #A6A6A6
$background: #e2e2e2
$background-input: #ededed

$button-height: 35px
$border-radius: 15px
$border-radius-input: 10px
$button-text-weight: 500
$button-text-size: 1rem