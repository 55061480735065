@import './vars.sass'
@import '~animate.css/animate.min'

html.touch *:hover 
    pointer-events: none !important

html
    font-size: 16px !important
    line-height: normal !important

html, body
    -webkit-font-smoothing: antialiased

h1, h2, h3, h4, p
    font-family: 'Poppins', sans-serif !important
    line-height: normal !important

p 
    font-size: 1rem

h1 
    font-size: 2.5rem

h2
    font-size: 1.5rem

h3 
    font-size: 1.1rem !important
    font-weight: 500 !important
    margin-bottom: 5px !important
    color: $title !important

app-active 
    position: absolute
    left: 0
    top: 0
    z-index: 1000
    background: #e2e2e2
    width: 100%
    height: 100vh

.mat-typography, .mat-table
    font-family: 'Poppins', sans-serif !important
    h1
      font-family: 'Poppins', sans-serif !important
      font-style: normal
      font-weight: 400

.mat-error 
    font-family: 'Poppins', sans-serif !important
    font-size: 12px
    font-weight: 200
    margin-top: 10px

body
    font-family: 'Poppins', sans-serif
    //background-color: $background
    margin: 0
    padding: 0
    
.btn:focus, .btn.focus, button:focus
    box-shadow: none
    outline: none

.navbar-home 
    background: #212529
    width: 100% 
    z-index: 500
    .navbar-nav
        flex-direction: row
        a
            font-size: 1rem
            color: #fff 
            margin-right: 2rem
            text-rendering: optimizeLegibility
        .btn:hover 
            color: #000

.home-cover
    background-image: url(assets/img/bck.jpg)
    height: 70vh
    margin: 0px
    padding: 0px
    max-width: 100% !important
    background-size: cover
    background-position: center right
    position: relative
    .overlay 
        position: absolute
        top: 0
        left: 0
        height: 100%
        width: 100%
        background-color: #000
        opacity: .52
    .site-heading 
        color: #fff
        text-align: center
        padding-top: 15vh
        position: relative
        text-align: left
        h1 
            font-size: 6rem !important
            font-weight: 800
            letter-spacing: 0.2rem
        .subheading
            font-size: 1.4rem
            font-weight: 400
            line-height: 6rem
            display: block

app-login-support
    background-color: #dadada
    width: 100%
    height: 100vh
    display: block

app-header 
    position: absolute
    right: 4rem
    margin-top: 2.2rem

app-login-admin
    width: 100%
    height: 100vh
    background: #eaeaea
    position: absolute
    top: 0
    left: 0

.main-container 
    background: #e2e2e2
    //min-height: calc( 100vh - 20px )
    max-width: calc( 100vw - 100px)
    display: inline-block
    float: right
    //margin: 10px 10px 10px 100px
    //border-radius: 3rem

// Sign In
.card-signin
    font-family: 'Poppins', sans-serif
    border-radius: 1rem !important
    border: 1px solid #212529
    font-size: 1.1rem
    background: #fff !important
    padding: 25px !important
    h2 
        font-size: 2rem
        margin-bottom: 15px
        font-family: inherit
    p 
        margin-bottom: 2rem
    .card-body 
        padding: 2rem 
        .btn-main 
            background-color: $icon
            color: #fff
            font-weight: $button-text-weight
            font-size: $button-text-size
            height: $button-height
            margin-bottom: 15px
            &:hover
               background-color: $icon 
        .btn-right 
            color: $title
            font-weight: $button-text-weight
            font-size: $button-text-size
    mat-form-field 
        font-family: inherit
        width: 100%
        margin-bottom: 0px
        font-size: $button-text-size

.modal-backdrop 
    z-index: 100

span 
    color: $subtitle
    font-size: 1rem


.profile-header 
    margin-top: 0px
    margin-right: 1rem
    h2 
        color: $subtitle !important
        line-height: 1.4rem
    span
        font-size: 1rem !important
        font-weight: 500 !important

.wrapper 
    min-height: 100vh

/********************************* 
    Sidebar
 **********************************/ 

app-sidebar 
    display: inline-block

#sidebar-wrapper
    width: 100px
    height: 100vh
    background-color: #fff
    position: fixed
    z-index: 100
    float: left
    top: 0
    bottom: 0
    overflow: auto
    .profile
        -webkit-clip-path: circle(40px at center)
        clip-path: circle(25px at center)
        overflow: hidden
        width: 50px
        height: 50px
        background-size: cover
        margin: 4rem 15px 2rem 15px
    .logo
        margin-left: auto
        margin-right: auto
        color: $icon
        margin-top: 20px
        margin-bottom: 0px
        height: 12vh
        img
            width: 50px
            height: 50px
            border-radius: 10px
    mat-icon
        line-height: unset
        font-size: 2rem
        display: block
        width: 3rem
        height: 3rem
        padding-top: 0.8rem
        margin-right: auto
        margin-left: auto
    .list-group-item
        color: #7d7d7d
        background-color: transparent !important
        text-align: center
        font-size: 10px
        text-rendering: optimizeLegibility
        font-weight: 100
        padding-left: 0px
        padding-right: 0px
        border: none
        span 
            display: block
            margin-top: 0.5rem
            font-size: 0.75rem
            font-weight: 500
            line-height: 1.2rem
    .exit 
        position: absolute
        bottom: 0
        cursor: pointer
    .list-group-item-action
        margin-top: inherit !important
    .list-group-item.active
        mat-icon
            color: #fff !important
            background-color: $icon
            border-radius: 10px
        span 
            color: $icon

mat-drawer-container
    .mat-drawer-opened ~ .mat-drawer-content
        .mat-column-date, .mat-column-created_by, .mat-column-closed_by, .mat-column-nit, .mat-column-description
            display: none

.admin-container 
    max-width: 100%
    min-height: 100vh
    padding: 0px

.sub-container 
    margin-left: 15px
    margin-right: 15px

/********************************* 
    Walktrough
 **********************************/

.mat-horizontal-content-container 
    padding: 0px !important
    position: relative

.mat-horizontal-stepper-content
    height: 60vh

.walkthrough 
    font-family: 'Poppins', sans-serif !important
    min-height: 50vh
    h3 
        font-family: 'Poppins', sans-serif !important
        font-size: 1.3rem
        font-weight: 800
        margin-top: 20px 
        color: $icon
    p 
        font-family: 'Poppins', sans-serif !important
        line-height: 1.2rem !important
    .terms 
        max-height: 42vh
        overflow-y: scroll
        margin-bottom: 2rem
        text-align: justify
        b 
            display: block
            margin-top: 30px
            margin-bottom: 10px
        br 
            display: block
            content: ""
            margin-top: 10px
    .btn-ok
        font-family: 'Poppins', sans-serif !important
        position: absolute
        margin-bottom: 0px !important
        bottom: 0
        font-weight: 400
        font-size: 1.2rem

.mat-horizontal-stepper-header-container 
    display: none !important

#walkthrough-carousel 
    height: 45vh
    display: block
    img 
        height: 40vh
        float: right
    .carousel-indicators
        li 
            width: 10px
            height: 10px
            background-color: $icon
            border-radius: 50%
            transition: none

.carousel-item
    font-family: 'Poppins', sans-serif !important
    h2 
        color: $icon
        font-size: 3rem !important
        font-weight: 900 !important
        line-height: 3.4rem
        margin-top: 50px
        position: absolute 
    p 
        color: $subtitle
        font-size: 1.2rem !important
        line-height: 1.8rem !important
        font-weight: 400 !important
        margin-top: 32%
        position: absolute

/********************************* 
    Dashboard
 **********************************/

.home-info 
    li 
        width: 25%
        display: inline-block
        padding: 10px 20px 10px 0px
        .box 
            font-family: 'Poppins', sans-serif !important
            font-size: 20px
            height: 100px
            border-radius: 10px
            color: #fff
            text-align: center
            display: -ms-flexbox
            display: -webkit-flex
            display: flex
            -ms-flex-align: center
            -webkit-align-items: center
            -webkit-box-align: center
            align-items: center
            p 
                margin-left: auto
                margin-right: auto
            span 
                display: block
                font-size: 2rem
                font-weight: 700
                margin-top: 10px
                color: #fff
        .purple 
            background-color: #886ba6
        .orange 
            background-color: #d9674a
        .blue 
            background-color: #755e8c
        .brown 
            background-color: #ea9797

.admin-dashboard
    padding: 1.8rem
    max-width: 100%
    .card 
        width: 100% 

.dashboard 
    font-family: 'Poppins', sans-serif !important
    h2 
        font-family: inherit
    ul 
        border-radius: $border-radius
    li 
        padding: 15px
        cursor: pointer
        h3
            font-family: inherit
        span 
            float: right 
            text-align: right
        p 
            color: $subtitle
        &:hover
            background: $hover

/********************************* 
    Tab Bar
 **********************************/

.mat-tab-list
    margin-left: 15px
    .mat-tab-label 
        font-family: 'Poppins', sans-serif !important
        height: 30px
        background: #fff
        color: $title
        border-radius: 10px
        opacity: 1
        min-width: auto
        margin-right: 10px
    .mat-tab-label-active
        background: $icon
        color: #fff
    .mat-ink-bar
        background: none !important
    
.mat-tab-body, .mat-tab-body-content, .mat-tab-body-wrapper 
    overflow: visible !important

.mat-tab-header
    border: none

.mat-tab-labels
    justify-content: left !important

/********************************* 
    Filter
 **********************************/

.mat-date-range-input
    width: 150px !important
    display: inline-block !important
    border-bottom: 2px solid $line

/********************************* 
    Alert
 **********************************/

.swal2-confirm 
    width: 100%
    background: $icon !important
    border-color: $icon !important
    margin-bottom: 15px
    color: #fff
    padding: 0px
    height: 45px

.swal2-actions 
    padding-left: 0px !important
    padding-right: 0px !important

.swal2-title 
    font-family: "Poppins", sans-serif !important
    font-size: 1.2rem
    font-weight: 400
    margin-bottom: 0px
    text-rendering: optimizeLegibility
    color: $title

.swal2-styled
    &:focus 
        outline: none !important
        box-shadow: none !important

.swal2-popup 
    font-family: 'Poppins', sans-serif !important
    border-radius: 15px !important
    width: 300px !important

.swal2-popup.swal2-loading 
    width: 150px !important
    height: 150px !important

.swal2-actions.swal2-loading 
    margin: 0px

.swal2-loader 
    border-color: $icon transparent $icon transparent !important
    width: 3em !important
    height: 3em !important

/********************************* 
    Buildings
 **********************************/

.block-list 
    .list-group-item 
        cursor: pointer
        button 
            display: none
            font-size: 18px
            color: $icon
            z-index: 500
        &:hover
            background: $hover
            button 
                display: inline-block
                &:hover 
                    color: $icon
    .active 
        background: $hover
        border-color: $hover

/********************************* 
    Contact
 **********************************/

.contact-list 
    .list-group-item 
        cursor: pointer
        margin-bottom: 1rem
        border-radius: $border-radius
        button 
            display: none
            font-size: 18px
            color: $icon
            z-index: 500
        &:hover
            background: $hover
            button 
                display: block
                &:hover 
                    color: $icon

.card-contact 
    background: #fff !important
    border-radius: 1rem !important
    .card-header
        margin-bottom: 0px !important
        h2 
            margin-bottom: 0px !important
            font-weight: 600 !important
    h3 
        display: inline-block
    .input
        input
            width: calc(100% - 35px)
            display: inline-block
        .remove 
            display: inline-block

/********************************* 
    Messages
 **********************************/

.message-form
    .line-right 
        border-right: 1px solid $line2
    .section-title
        display: block
        margin-bottom: 10px
    
.messages 
    border: 10px
    background: #fff
    font-size: 1rem
    .list 
        padding-right: 0px 
        height: 85vh
        overflow: scroll
        border-right: 1px solid $line
        .list-group 
            border: none 
    .detail 
        padding-left: 0px
        .container 
            max-width: 100%
            padding: 0px
            .form-group
                text-align: left
            .col-sm-2
                max-width: 100px
                padding-top: 0px
        .info
            width: 100%
            padding: 15px
            .row
                margin-left: 0px
                margin-right: 0px
            p
                display: inline-block
                padding: 0px
            label 
                padding: 0px
                max-width: 120px
        .message-body
            padding: 15px
        hr 
            margin-top: 0px
            margin-bottom: 0px
    .list-group-item 
        cursor: pointer 
        border: none 
        border-bottom: 1px solid $line2
        &:hover 
            background: $hover
        p 
            font-family: inherit
            font-size: 1rem
            color: $title
            margin-bottom: 0px
        span
            float: right
            font-size: 0.8rem
            text-align: right
            color: $subtitle
    .message-body
        p 
            margin-bottom: 50px
        .files 
            display: block
            li 
                width: 120px
                height: 170px
                display: inline-block
                overflow: hidden
                margin-right: 10px
                padding: 0px
                h2 
                    font-size: 14px
                    padding: 0px
                    line-height: 16px
                .preview 
                    height: 100px
                    background-size: cover
                    background-position: center
                    background-repeat: no-repeat
                    border-radius: 3px
                    overflow: hidden
                    margin-bottom: 10px
            
/********************************* 
    Requests
 **********************************/

.clearfix 
    overflow: auto
    &::after
        content: ""
        clear: both
        display: table

.requests 
    height: 85vh
    border: 10px
    font-size: 1rem
    background: $background-input
    .row
        margin-right: 0px
    .list 
        padding-right: 0px 
        height: 85vh
        overflow: scroll
        border-right: 1px solid $line
        .list-group 
            border: none 
        li 
            padding: 15px
            h2
                color: $icon
                margin-bottom: 0px
            h3 
                font-size: 0.9rem
                margin-bottom: 10px
                color: $subtitle
            p 
                margin-bottom: 0px
    .detail 
        padding-left: 0px
        padding-right: 0px
        height: 85vh 
        position: relative
        .container 
            padding: 0px
            height: 100%
            .message-body
                height: 100%
            .p-footer
                padding-bottom: 150px
            .info 
                background: #fff
                padding: 15px
                position: relative
                .btn-ok 
                    background: $icon
                    border-color: $icon
                    color: #fff
                    position: absolute
                    font-weight: $button-text-weight
                    right: 20px
                    border-radius: $border-radius-input
                h2
                    font-family: 'Poppins', sans-serif !important
                    font-size: 1.4rem
                    margin-bottom: 0px
                p 
                    font-size: 1rem
                    margin-bottom: 0px
    .list-group-item 
        cursor: pointer 
        border: none 
        border-bottom: 1px solid $line2
        background: #fff
        &:hover 
            background: $hover
        h2 
            font-family: 'Poppins', sans-serif !important
            font-size: 1rem
            padding-right: 90px
            color: $title
            font-weight: 500
            line-height: normal
            margin-bottom: 10px
        h3
            font-family: 'Poppins', sans-serif !important
            font-size: 1rem
        span
            float: right
            font-size: 0.8rem
            text-align: right
            color: $subtitle
    .closed 
        background: $disable
    .message-body
        overflow: scroll
        opacity: 0
        padding-bottom: 100px
        .messages 
            display: block
            background: transparent
            li 
                background: transparent
                border: none
                &::after
                    content: ""
                    display: block
                    height: 0px
                    clear: both
                    visibility: hidden
                &::before
                    content: ""
                    display: block
                    height: 0px
                    clear: both
                    visibility: hidden
                .left 
                    float: left
                .right 
                    float: right
                .message
                    width: -webkit-fit-content
                    min-width: 250px
                    max-width: 80%
                    background: #fff
                    padding: 15px
                    height: -webkit-fit-content
                    border-radius: 10px
                    .user 
                        font-size: 1rem
                        float: none
                    ul 
                        list-style: none 
                        img 
                            max-width: 250px
                            border-radius: 5px
                            margin-top: 15px
                            width: 100%
                    h3 
                        font-weight: 500
                        margin-bottom: 0px
                    p 
                        margin-bottom: 0px
                .files
                    margin-top: 15px
                    padding-top: 0px
                    li 
                        display: block
    .footer
        background: #fff
        z-index: 100
        bottom: 0
        width: 100%
        position: absolute
        padding: 15px
        border-top: 1px solid rgba(0,0,0,.1)
        border-bottom-right-radius: 10px
        .images
            .preview 
                height: 50px
                width: 50px
                border-radius: 5px
                margin-right: 10px
                background-size: cover
                background-position: center
                background-color: $background
                display: inline-block
                position: relative
                &:hover
                    button
                        display: block
                button
                    position: absolute
                    background: red
                    color: white
                    top: -10px
                    right: -8px
                    width: 20px
                    height: 20px
                    border-radius: 10px
                    padding: 0px
                    display: none
                    span 
                        font-size: 15px
                        padding-top: 2px
        mat-form-field 
            font-family: 'Poppins', sans-serif !important
            width: calc( 100% - 100px )
            margin-bottom: 0px
        .mat-form-field-wrapper
            margin-bottom: 0px
            padding-bottom: 0px
        .buttons
            display: inline-block
            float: right
        .btn 
            background: $icon
            width: 40px
            height: 40px
            vertical-align: top
            margin-left: 5px
            border-radius: 20px
            padding: 6px 0px
            span
                color: #fff
        .send 
            padding-left: 5px
        .file
            display: none !important

.lightboxOverlay 
    max-height: 100vh
    overflow: hidden
    position: fixed

/********************************* 
    Places
 **********************************/

.modal-place 
    .mat-horizontal-stepper-content
        height: auto

.place-image 
    width: 100%
    height: 200px 
    background-image: url('assets/img/placeholder.jpg')
    background-size: cover
    background-position: center
    border-radius: 5px
    .inner 
        background-color: #fff
        width: 40px
        height: 40px
        border-radius: 100%
        position: absolute
        bottom: 0
        right: 0
        margin-bottom: 10px
        margin-right: 25px
        &:hover
            background-color: $icon
            color: #fff
        button
            padding: 7px
            color: $icon
            &:hover
                color: #fff

/********************************* 
    Booking
 **********************************/

.cal-open-day-events 
    div 
        display: inline-block
        > .cal-event 
            display: none
        mwl-calendar-event-title
            background-color: #333
            display: inline-block
            padding: 5px 10px 5px 10px
            margin-right: 10px
            margin-bottom: 10px
            border-radius: 0.5rem
            color: #fff
            cursor: pointer
            &:hover 
                color: $title
                background: $hover

.cal-event-title
    font-size: 1rem
    font-weight: 400
    &:hover 
        text-decoration: none !important
    span 
        display: block
        font-size: 0.8rem
        font-weight: 200

/********************************* 
    Logbook
 **********************************/

.logbook 
    font-family: 'Poppins', sans-serif !important
    ul 
        list-style: none
    li 
        background: #fff 
        padding: 15px
        margin-bottom: 20px
        border-radius: $border-radius
        width: fit-content
        min-width: 300px
        max-width: 500px
        h3 
            font-family: inherit
        span 
            font-family: inherit
            float: right
            text-align: right
        img 
            width: 100%
            border-radius: 5px
            cursor: pointer

/********************************* 
    Table
 **********************************/

.card 
    background: transparent
    border-radius: 3rem
    padding: 0.3rem 1.25rem 0 1.2rem
    border: none
    .card-header 
        font-family: 'Poppins', sans-serif
        background: transparent
        padding: 0px
        margin-top: 20px
        margin-bottom: 20px
        border-bottom: none
        .options
            display: inline-block
            vertical-align: top
            float: right
            button
                font-family: inherit
                height: $button-height
                font-weight: $button-text-weight
                font-size: $button-text-size
            .filter 
                float: right
            select 
                font-family: inherit
                height: $button-height
                width: 130px
                margin-left: 15px
                border: none
                outline: none
                box-shadow: none
                color: $subtitle
                background: $background-input url(assets/icon/expand.svg) no-repeat 9px center
                -webkit-appearance: none
                -webkit-border-radius: $border-radius-input
                -moz-border-radius: $border-radius-input
                border-radius: $border-radius-input
                padding: 0px 10px 0px 35px
            input[type=search]
                font-family: inherit
                height: $button-height
                width: 55px
                -webkit-appearance: textfield
                -webkit-box-sizing: content-box
                font-size: 100%
                background: #ededed url(assets/icon/search.svg) no-repeat 9px center
                border: none
                padding: 0px 10px 0px 35px
                -webkit-border-radius: $border-radius-input
                -moz-border-radius: $border-radius-input
                border-radius: $border-radius-input
                -webkit-transition: all .5s
                -moz-transition: all .5s
                transition: all .5s
                margin-left: 15px
                &:focus
                    width: 130px
                    background-color: #fff
                    -webkit-box-shadow: none
                    -moz-box-shadow: none
                    box-shadow: none
                    outline: none
            input::-webkit-search-decoration, input::-webkit-search-cancel-button
                display: none
            mat-button-toggle-group 
                vertical-align: bottom
                border: none
                 -webkit-border-radius: $border-radius-input
                -moz-border-radius: $border-radius-input
                border-radius: $border-radius-input
                button 
                    margin-left: 0px
                    background: transparent
                    color: $subtitle
                .mat-button-toggle-label-content
                    padding: 0px
                    line-height: initial
                    font-family: 'Poppins', sans-serif
                .mat-button-toggle-appearance-standard
                    background-color: $background-input
                .mat-button-toggle-checked
                    background-color: $icon
                    button
                        color: #fff
                    
        h1 
            font-size: 2rem
            font-weight: 500
            text-rendering: optimizeLegibility
            margin-bottom: 0px
            vertical-align: bottom
            color: $subtitle
            display: inline-block
        h2 
            font-size: 1.2rem
            line-height: 1.8rem
            margin-bottom: 2rem
            font-weight: 500
            text-rendering: optimizeLegibility
            color: $subtitle
        button 
            background: $icon
            color: #fff
            font-size: 1rem
            font-weight: 500
            margin-left: 20px
            height: $button-height
            padding: 0px 20px
            border-radius: 10px
            line-height: 0px
            vertical-align: top
            &:hover
                background: $blue
                color: #fff
        .mat-date-range-input
            vertical-align: top
        .mat-datepicker-toggle-default-icon
                top: 4px
                left: 8px
        .mat-form-field-prefix
            mat-icon 
                color: $icon
        .mat-form-field-flex
            border-bottom: 2px solid $line
        form
            float: right
            .mat-form-field-underline
                background: none
    .card-body 
        overflow: hidden
        border-radius: 10px
        padding-top: 0px
        padding: 0px
        .mat-row 
            height: auto 
        .mat-cell 
            color: #000
            font-size: 1rem
            text-align: start
            padding-right: 10px
            flex-basis: 0
            flex-grow: 1
            max-width: 100%
            align-self: normal
            &::after
                content: ""
                display: block
                height: 20px
                clear: both
                visibility: hidden
            &::before
                content: ""
                display: block
                height: 20px
                clear: both
                visibility: hidden
        .mat-table
            margin-left: 15px
            margin-right: 15px
            img 
                margin-bottom: 20px
        .mat-header-row
            line-height: 3rem
        .mat-header-row, .mat-row
            display: flex
            flex-wrap: wrap
            margin-right: -15px
            margin-left: -15px
        .mat-header-cell
            font-weight: 500
            font-size: 0.9rem
            text-align: left !important 
            flex-basis: 0
            flex-grow: 1
            max-width: 100%
            align-self: center
            color: $subtitle
            font-weight: 600
            text-rendering: optimizeLegibility
        .mat-column-id
            max-width: 80px !important
        .mat-column-actions 
            max-width: 110px !important
            padding: 0 !important
            mat-icon 
                color: $icon
            &::before
                height: 10px
            &::after
                height: 10px
    ul 
        padding-left: 0px
    .mat-column-date
        max-width: 80px
    .mat-column-client
        max-width: 45%
        width: 35%
        min-width: 25%
    .mat-column-description
        max-width: 400px
        min-width: 300px
    .mat-column-closed
        max-width: 100px
    table
        width: calc( 100% - 30px )
    mat-paginator 
        margin-bottom: 50px
        border-bottom-left-radius: 15px
        border-bottom-right-radius: 15px
    .mat-paginator-range-actions
        margin-right: 15px
        button 
            padding-bottom: 30px
    
.header 
    h2 
        font-size: 1.4rem
        font-weight: 500
        margin-bottom: 0px
        text-rendering: optimizeLegibility
        color: $title
    h3 
        font-size: 1.2rem
        margin-bottom: 0px 
        color: $subtitle
        font-weight: 400
    span 
        font-size: 0.8rem
        font-weight: 200
        color: $subtitle
            
.list-header
    margin-bottom: 5px
    margin-top: 20px
    h2 
        font-size: 1.2rem
        font-weight: 600
        text-rendering: optimizeLegibility
        margin-bottom: 15px
        color: #595959
        display: inline-block
    button 
        background: $icon
        color: #fff
        font-size: $button-text-size
        font-weight: $button-text-weight
        margin-left: 20px
        height: $button-height
        padding: 0px 20px
        border-radius: 10px
        line-height: 0px
        &:hover
            background: $blue
            color: #fff

.block-list 
    border-radius: 15px  
    h3
        font-family: 'Poppins', sans-serif !important
        margin: 0px
    button
        right: 10px
        top: 5px

.apartment 
    column-count: 6
    .card 
        padding: 0rem
        cursor: pointer
        .card-body 
            background: #fff
            border-radius: 15px
            padding: 1rem
            border: 1px solid #fff
            &:hover
                background: $hover
                border: 1px solid rgba(0,0,0,.125)
            &:focus
                outline: none
            h5 
                font-family: 'Poppins', sans-serif !important
                text-align: center
                font-size: 1.2rem
                font-weight: 500
                color: $title
                margin-top: auto
                margin-bottom: auto
            button 
                position: absolute
                top: 0.5rem
                right: 0.1rem
                mat-icon 
                    font-size: 1rem 

mat-spinner 
    margin-left: auto
    margin-right: auto
    margin-top: auto
    margin-bottom: auto
    top: 40%
    -ms-transform: translateY(-50%)
    transform: translateY(-50%)
    position: absolute !important
    right: 0
    left: 0

/********************************* 
    Modal
 **********************************/

.cdk-overlay-pane
    max-height: 100vh
    overflow-y: scroll

mat-dialog-container
    padding: 15px !important
    border-radius: 15px !important
    font-family: 'Poppins', sans-serif
    box-shadow: none !important
    .modal-ag
        padding: 10px
        h2 
            color: $title
            font-size: 1.8rem
            font-weight: 500
            font-family: inherit
            margin-bottom: 30px
            text-rendering: optimizeLegibility
        .form-group
            margin-bottom: 0px
        mat-form-field
            font-family: inherit
            width: 100%
        button  
            height: 45px
            font-size: $button-text-size
            font-weight: $button-text-weight
            border-radius: $border-radius-input
            vertical-align: top
        .btn-ok
            background: $icon
            border-color: $icon
            margin-bottom: 15px
            color: #fff

/********************************* 
    Website
 **********************************/

.space-50
    height: 50px

.space-25
    height: 25px

.calendly-badge-widget
    position: fixed !important
    right:20px
    bottom:15px
    z-index:9998
    cursor: pointer
    .calendly-badge-content
        display:table-cell
        width:auto
        height:45px
        line-height: 45px
        padding:0 30px
        border-radius:25px
        box-shadow:rgba(0,0,0,.25)
        font-family:sans-serif
        text-align:center
        vertical-align:middle
        font-weight:700
        font-size:14px
        color:#fff
        cursor:pointer
        .calendly-white
            color:#666a73
        span
            display:block
            font-size:12px

.calendly-spinner
    position:absolute
    top:50%
    left:0
    right:0
    transform:translateY(-50%)
    text-align:center
    z-index:-1
    div
        display:inline-block
        width:18px
        height:18px
        background-color:#e1e1e1
        border-radius:50%
        vertical-align:middle
        animation-fill-mode:both
    .calendly-bounce1
        animation-delay:-.32s
    .calendly-bounce2
        animation-delay:-.16s